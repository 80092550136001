/* * {
  margin: 0;
  padding: 0;
  font-family: "Trebuchet MS", sans-serif;
  background-color: #060423;
  box-sizing: border-box;
} */

/* html {
  overflow-y: hidden !important ;
  overflow-x: hidden !important;
}  */



.wrapper_f {
  width: 100%; 
  height: 100%;
  text-align: center;
  padding-top: 5px;
  /* overflow-y: scroll;
  scrollbar-width: 0px !important; */
}

.wrapper_f {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  letter-spacing: 4px;
}

.title{
  font-size: 50px;
  color: #060423;
  font-weight: 700;
  text-align: center;
  letter-spacing: 4px;
}

.container_f {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

}

.container_f .option_item {
  display: block;
  position: relative;
  width: 400px;
  height: 250px;
  margin: 10px;
}

.option_item .option_inner {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  display: block;
  border: 5px solid;
  position: relative;
}

.option_item .checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 0;
  opacity: 1;
}

.option_item .option_inner .name {
  user-select: none;
  font-size: 30px;
  margin: 2px 0px;
}

.name {
  letter-spacing: 2px;
}

.option_item .checkbox:checked ~ .option_inner {
  border-color: #e67002;
  color: #e67002;
}

/* .option_item .option_inner .tickmark {
position: absolute;
top: 0px;
left: 0px;
border: 25px solid;
border-color: rgb(255, 255, 255) transparent transparent rgb(255, 255, 255);
} */

.image-2 img {
  width: 100%;
  height: 190px;
}

.genre {
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-decoration: solid;
  margin-bottom: 10px;
}

.genre {
  letter-spacing: 3px;
}

.hero-btn {
  display: inline-block;
  color: white;
  border: 1px solid white;
  padding: 12px 34px;
  font-size: 15px;
  background: transparent;
  position:relative;
  margin-top: 20px; 
  margin-bottom: 20px;
  cursor: pointer;
}

.hero-btn:hover {
  border: 1px solid #e67002;
  background: #e67002;
  transition: 0.5s;
}

.genre a{
  float: right;
  margin-right: 50px;
}

.wrapper_f{
  z-index: 200 !important;
}

/* .fag-product-page{
  scrollbar-width: 0px !important;
} */


/* ---------- MOBILE VIEW ---------- */
@media (max-width: 719px) {
  .wrapper_f {
    width: 100%; 
    height: 100%;
    text-align: center;
    padding-top: 5px;
  }

  .container_f {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .option_item{
    height: 160px !important;
    width: 155px !important;
  }

  .container_f .option_item {
    margin: 20px 10px !important;
  }

  .image-2 img{
    height: 125px !important;
  }

  .genre{
    font-size: 20px !important;
  }

  .genre a{
    margin-right: 0px !important;
    margin-top: -5px;
  }

  .name{
    font-size: 20px !important;
    margin: 0px !important;
  }
}

/*---------iPad View -----------*/
@media (min-width: 720px) and (max-width: 769px) {
  .option_item{
    width: 315px !important;
  }
}

/* -----iPad Pro VIEW ----- */
@media (min-width: 770px) and (max-width: 1024px){
  .option_item{
    height: 250px !important;
    width: 435px !important;
  }
}


